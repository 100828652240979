import './style.css';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import Overlay from 'ol/Overlay';
import GeoJSON from 'ol/format/GeoJSON';
import {Icon, Style, Fill, Stroke} from 'ol/style';
// import sync from 'ol-hashed'; // need to import if using sync
import { fromLonLat } from 'ol/proj';
import {Control, ZoomToExtent, defaults as defaultControls} from 'ol/control';
import {Vector as VectorSource} from 'ol/source';
import LayerGroup from 'ol/layer/Group';
import LayerSwitcher from 'ol-layerswitcher';
import BingMaps from 'ol/source/BingMaps.js';
import * as olExtent from 'ol/extent';
import {getCenter} from 'ol/extent';
import {
  BROWSER_INPUT_ELEMENT_ID,
  BROWSER_SUGGESTIONS_ELEMENT_ID,
  BROWSER_SUGGESTIONS_MAX_SIZE,
} from './config.mjs';
import { AppDropdownElement } from './dropdown-element.mjs';
import { fuzzySearch } from './fuzzy-search.mjs';


// function to get inner window height for mobile browsers and set body height to it to prevent scrolling, or hidden content behind the bottom nav bar
window.onresize = function() {
  document.body.height = window.innerHeight;
}
window.onresize(); // called to initially set the height.

let initialViewportWidth = window.innerWidth;
//console.log(initialViewportWidth);
const panel_close = "https://trugis.ca/wp-content/uploads/2023/02/chevron-left-icon_blu_sm.png";
const panel_open = "https://trugis.ca/wp-content/uploads/2023/02/chevron-right-icon_blu_sm.png";

const edAgencies_markerURL ='https://trugis.ca/wp-content/uploads/2024/03/Asset-23@0.5x.png';
const consultants_markerURL ='https://trugis.ca/wp-content/uploads/2024/03/Asset-25@0.5x.png';
const DMOs_markerURL ='https://trugis.ca/wp-content/uploads/2024/03/Asset-29@0.5x.png';
const education_markerURL ='https://trugis.ca/wp-content/uploads/2024/03/Asset-26@0.5x.png';
const indigenous_markerURL ='https://trugis.ca/wp-content/uploads/2024/03/Asset-27@0.5x.png';
const retentionExpansion_markerURL ='https://trugis.ca/wp-content/uploads/2024/03/Asset-28@0.5x.png';
const funding_markerURL ='https://trugis.ca/wp-content/uploads/2024/03/Asset-30@0.5x.png';
const municipalChambers_markerURL ='https://trugis.ca/wp-content/uploads/2024/03/Asset-24@0.5x.png';
const industrySpecific_markerURL ='https://trugis.ca/wp-content/uploads/2024/03/Asset-32@0.5x.png';

const edAgencies_member_markerURL = 'https://trugis.ca/wp-content/uploads/2024/03/Asset-63@0.5x.png'; 
const consultants_member_markerURL = 'https://trugis.ca/wp-content/uploads/2024/03/Asset-61@0.5x.png'; 
const DMOs_member_markerURL = 'https://trugis.ca/wp-content/uploads/2024/03/Asset-57@0.5x.png'; 
const education_member_markerURL = 'https://trugis.ca/wp-content/uploads/2024/03/Asset-60@0.5x.png'; 
const indigenous_member_markerURL = 'https://trugis.ca/wp-content/uploads/2024/03/Asset-59@0.5x.png'; 
const retentionExpansion_member_markerURL = 'https://trugis.ca/wp-content/uploads/2024/03/Asset-58@0.5x.png'; 
const funding_member_markerURL = 'https://trugis.ca/wp-content/uploads/2024/03/Asset-56@0.5x.png'; 
const municipalChambers_member_markerURL = 'https://trugis.ca/wp-content/uploads/2024/03/Asset-62@0.5x.png'; 
const industrySpecific_member_markerURL = 'https://trugis.ca/wp-content/uploads/2024/03/Asset-55@0.5x.png'; 

const googleSheet_Id = '14Q1hWmnYRULc39g20CnkO9d5kjdIZw3qYJWr_56P32I';
const tab_allAssets = 'Master';

const allAssets_URL = `https://opensheet.elk.sh/${googleSheet_Id}/${tab_allAssets}`

const geoServerDomain = 'https://geoserver.marktrueman.ca/geoserver/'
const nameSpace = 'TruGIS_Admin_Layers'
const service = 'WFS'
const version = '2.0.0'
const request = 'GetFeature'
const layerName = 'census_divisions_3857'
const typeName = `${nameSpace}%3A${layerName}`
const count = 30

const regionsWFS_URL = `${geoServerDomain}${nameSpace}/ows?service=${service}&version=${version}&request=${request}&typeName=${typeName}&count=${count}&outputFormat=application%2Fjson`

/* ****Region center Coords **** */
const initialView = [-13004708.81, 6652457.134]
const britishColumbia = [-13944609.48, 7355303.448]
const canada = fromLonLat([-95.444, 57.62])
const initialExtent = [ -13413442.63240057, 6233305.097799245, -12853493.531286297, 6504847.982355047 ]


const regions = [
  {
    id: 0,
    region:"Region-wide", 
    coords: initialView, 
    zoom: 7
  },
  {
    id: 1,
    region: "East Kootenay",
    coords: [-12872102.1712063, 6442207.5268955],
    zoom: 8,
  },
  {
    id: 2,
    region: "Central Kootenay",
    coords: [-13057827.208462, 6422243.56763239],
    zoom: 8,
  },
  {
    id: 3,
    region: "West Kootenay / Boundary",
    coords: [-13203476.1728676, 6335242.47847719],
    zoom: 9,
  },
  {
    id: 4,
    region: "Okanagan-Similkameen",
    coords: [-13374075.4037413, 6342394.00400602],
    zoom: 9,
  },
  {
    id: 5,
    region: "Fraser Valley",
    coords: [-13567797.3461371, 6369429.80726423],
    zoom: 9,
  },
  {
    id: 6,
    region: "Metro Vancouver",
    coords: [-13686688.9924581, 6318953.5312105],
    zoom: 9.5,
  },
  {
    id: 7,
    region: "Capital",
    coords: [-13767061.7576621, 6201201.79168739],
    zoom: 9,
  },
  {
    id: 8,
    region: "Cowichan Valley",
    coords: [-13816142.2342354, 6243805.60999907],
    zoom: 9,
  },
  {
    id: 9,
    region: "Nanaimo",
    coords: [-13826515.0476674, 6313512.69298863],
    zoom: 9,
  },
  {
    id: 10,
    region: "Alberni-Clayoquot",
    coords: [-13965843.511783, 6301714.54399404],
    zoom: 9,
  },
  {
    id: 11,
    region: "Strathcona",
    coords: [-14005554.2047969, 6494235.93733963],
    zoom: 9,
  },
  {
    id: 12,
    region: "Comox Valley",
    coords: [-13918773.1140358, 6387698.72068898],
    zoom: 9,
  },
  {
    id: 13,
    region: "Powell River",
    coords: [-13842547.221219, 6471190.92109047],
    zoom: 9,
  },
  {
    id: 14,
    region: "Sunshine Coast",
    coords: [-13778086.5053536, 6407015.08750806],
    zoom: 9,
  },
  {
    id: 15,
    region: "Squamish-Lillooet",
    coords: [-13678756.1780993, 6533442.79038177],
    zoom: 9,
  },
  {
    id: 16,
    region: "Thompson-Nicola",
    coords: [-13410918.9441309, 6652412.15174337],
    zoom: 8,
  },
  {
    id: 17,
    region: "Central Okanagan",
    coords: [-13298005.2632068, 6437522.74775212],
    zoom: 9,
  },
  {
    id: 18,
    region: "North Okanagan",
    coords: [-13222983.1815477, 6510308.3190844],
    zoom: 9,
  },
  {
    id: 19,
    region: "Columbia Shuswap",
    coords: [-13130344.5669305, 6690705.04270286],
    zoom: 7.5,
  },
  {
    id: 20,
    region: "Cariboo",
    coords: [-13703592.9708996, 6864838.17084056],
    zoom: 9,
  },
  {
    id: 21,
    region: "Mount Waddington",
    coords: [-14132937.2693344, 6593056.91706436],
    zoom: 9,
  },
  {
    id: 22,
    region: "Central Coast",
    coords: [-14178934.5073017, 6799661.49572681],
    zoom: 9,
  },
  {
    id: 23,
    region: "Kitimat-Stikine",
    coords: [-14421262.9309269, 7575382.8128719],
    zoom: 9,
  },
  {
    id: 24,
    region: "Bulkley-Nechako",
    coords: [-13997292.6783302, 7278360.41653474],
    zoom: 9,
  },
  {
    id: 25,
    region: "Fraser-Fort George",
    coords: [-13545504.4582828, 7180521.76368864],
    zoom: 7.5,
  },
  {
    id: 26,
    region: "Peace River",
    coords: [-13688065.0529762, 7693354.09547836],
    zoom: 9,
  },
  {
    id: 27,
    region: "Stikine",
    coords: [-14549812.3508587, 8125037.19081459],
    zoom: 9,
  },
  {
    id: 28,
    region: "Northern Rockies",
    coords: [-13742571.3122591, 8199396.30862723],
    zoom: 9,
  },
  {
    id: 29,
    region: "Skeena-Queen Charlotte",
    coords: [-14623722.1637677, 7095527.51978165],
    zoom: 9,
  },
  { 
    id: 30,
    region: "Out-of-Province",
    coords: canada,
    zoom: 4.5,
  },
  {
    id: 31,
    region: "Unknown Region",
    coords: britishColumbia,
    zoom: 5,
  },
 ]
  

const region_extents = [
  {region:'British Columbia', id: 0, extent: [-15479220.2129, 6148547.1602 , -12696406.2924, 8399751.5896]},
  {region:'Kootenay Region', id: 1, extent: [-15479220.2129, 6148547.1602 , -12696406.2924, 8399751.5896]},
  {region:'East Kootenay', id: 2, extent: [-13001732.0752893, 6274666.34957851, -12696406.2924188, 6680143.3768906]},
  {region:'Central Kootenay', id: 3, extent: [-13190901.8262452, 6274861.39616826, -12915822.9973874, 6632987.91865797]},
  {region:'West Kootenay', id: 4, extent: [-13037653.751800524, -13037653.751800524, -13037653.751800524, -13037653.751800524]},
  {region:'Kootenay Boundary', id: 5, extent: [-13288865.039813, 6274861.39510199, -13076191.9216738, 6413912.48864434]},
  {region:'Columbia/Shuswap', id: 6, extent: [-13325448.5178459, 6516197.42277402, -12941609.224422, 6891085.33561087]},
  {region:'Columbia Valley', id: 7, extent: []},
  {region:'Lower Columbia', id: 8, extent: [-13150841.9421604, 6274892.68598908, -13091608.6545956, 6319727.36990041]},
  {region:'Creston and Area', id: 9, extent: []},
  {region:'Trail and Area', id: 10, extent: []},
  {region:'Castlgar and Area', id: 11, extent: []},
  {region:'Nelson and Area', id: 12, extent: []},
  {region:'Kaslo and Area', id: 13, extent: []},
  {region:'Slocan Valley', id: 14, extent: []},
  {region:'Nakusp and Area', id: 15, extent: []},
  {region:'Revelstoke and Area', id: 16, extent: []},
  {region:'Valemount and Area', id: 17, extent: []},
]

window.addEventListener('DOMContentLoaded', function() {  
  //console.log(initialViewportWidth)
  if (initialViewportWidth <= 625 && initialViewportWidth > 480) {
    let wrapper = document.getElementById("wrapper");
    wrapper.classList.add("no-list");
  } else {
    let wrapper = document.getElementById("wrapper");
    wrapper.classList.remove("no-list");
  }
 
  if (initialViewportWidth <= 480) {
    let string_value = document.getElementById("string-value");
    string_value.multiple = false;
    let legend = document.getElementById("legend-wrapper");
    legend.style.display = "none";
  } else {
    let string_value = document.getElementById("string-value");
    string_value.multiple = true;
    let legend = document.getElementById("legend-wrapper");
    legend.style.display = "block";
  }
});

/* ******************  data ******************* */
const spinnerContainer = document.querySelector('.spinner-container');
spinnerContainer.style.display = 'block';

async function fetchData() {
  spinnerContainer.style.display = 'block';
  try {
    const [jsonObj, regions_geojson_WFS] = await Promise.all([
      fetch(allAssets_URL).then(res => res.json()),
      fetch(regionsWFS_URL).then(res => res.json())
    ]);

    // // Sort the array by removing any prefixes and using last name 
    // jsonObj.sort((a, b) => {
    //   const prefixRegex = /^(Dr\.|Mr\.|Ms\.|Mrs\.|Prof\.|Rev\.)\s+/i;
    //   const lastNameA = a.name.replace(prefixRegex, '').split(' ').slice(-1)[0].toUpperCase();
    //   const lastNameB = b.name.replace(prefixRegex, '').split(' ').slice(-1)[0].toUpperCase();
    //   if (lastNameA < lastNameB) {
    //     return -1;
    //   }
    //   if (lastNameA > lastNameB) {
    //     return 1;
    //   }
    //   return 0;
    // });
    console.log('jsonObj:', jsonObj);
    
    jsonObj.forEach((obj, index) => {
      obj.id = index;
    });

    // to GeoJSON Point array
    const geoJSONPointArr = jsonObj.map((row, index) => {
      return {
        "geometry_name": "geom",
        "id": "EDPN_Assets." + index,
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": fromLonLat([row.longitude, row.latitude])
        },
        "properties": row
      }
    });
    console.log('geoJSONPointArr:', geoJSONPointArr);
    
    // to GeoJSON.FeatureCollection
    const geojsonRaw = {
    "type": "FeatureCollection",
    
    "features": geoJSONPointArr,
    "crs": {
      "type": "name",
      "properties": { "name": "urn:ogc:def:crs:EPSG::3857" }
      }
    }
    console.log('geojsonRaw:', geojsonRaw);

    const geojsonObj = checkAndAdjustCoordinates(geojsonRaw);
    console.log('geojsonObj 397:', geojsonObj);
    console.log('fetch complete')
    return { jsonObj, geoJSONPointArr, geojsonObj, regions_geojson_WFS };
} catch (error) {
    console.log('Error fetching data:', error);
    location.reload();
  }
}

const { jsonObj, geoJSONPointArr, geojsonObj, regions_geojson_WFS } = await fetchData();

// replace spinner with map when data fetched
spinnerContainer.style.display = 'none';
const outer_wrapper = document.getElementById('outer-wrapper');
outer_wrapper.style.display = 'flex';

// function to adjust coordinates of overlapping points
function checkAndAdjustCoordinates(geojson) {
  const maxDistance = 20;
  const cellSize = maxDistance * 3;
  const features = geojson.features;

  // Create a dictionary of cells, where each cell is a list of features
  const cells = {};
  features.forEach(feature => {
    const [lat, lng] = feature.geometry.coordinates;
    const cellX = Math.floor(lat / cellSize);
    const cellY = Math.floor(lng / cellSize);
    const cellId = `${cellX},${cellY}`;
    if (!cells[cellId]) {
      cells[cellId] = [];
    }
    cells[cellId].push(feature);
  });

  // Check for overlapping features within each cell and adjacent cells
  Object.values(cells).forEach(cell => {
    for (let i = 0; i < cell.length - 1; i++) {
      const coord1 = cell[i].geometry.coordinates;
      for (let j = i + 1; j < cell.length; j++) {
        const coord2 = cell[j].geometry.coordinates;
        const distance = Math.sqrt((coord2[0] - coord1[0]) ** 2 + (coord2[1] - coord1[1]) ** 2);
        if (distance <= maxDistance) {
          coord1[0] += 10;
          coord1[1] += 10;
          coord2[0] -= 10;
          coord2[1] -= 10;
        }
      }
    }
  });
  return geojson;
}

// create array of unique regions from jsonObj
const asset_regions = [...new Set(jsonObj.map(item => item.region))];
console.log('asset_regions:', asset_regions);

const assets = [...new Set(jsonObj.map(item => item.organization))];
console.log('assets:', assets);

// filter regions using active_regions array
const active_regions = regions.filter(region => asset_regions.includes(region.region));
//console.log(active_regions);

/* **** Filter data based on tag for consumption as different layers **** */
function filterGeoJSONByProperty(geojson, property1, property2, property3, value) {
  return {
    type: geojson.type,
    features: geojson.features.filter(feature => {
      return feature.properties[property1] === value || (feature.properties[property2] === value && feature.properties[property2] != "Not Specified") || (feature.properties[property3] === value && feature.properties[property3] != "Not Specified");
    })
  };
}
function filterGeoJSONByPropertyMember(geojson, property1, value) {
  return {
    type: geojson.type,
    features: geojson.features.filter(feature => {
      return feature.properties[property1] === value;
    })
  };
}

const edpnMembers_geojsonObj = filterGeoJSONByPropertyMember(geojsonObj, "edpn_member", "yes");
console.log('edpnMembers_geojsonObj:', edpnMembers_geojsonObj);
const edAgencies_geojsonObj = filterGeoJSONByProperty(geojsonObj, "organization_asset_type", "tags2", "tags3", "Economic Development Agencies");
const consultants_geojsonObj = filterGeoJSONByProperty(geojsonObj, "organization_asset_type", "tags2", "tags3", "Private Economic Development Consultant");
const DMOs_geojsonObj = filterGeoJSONByProperty(geojsonObj, "organization_asset_type", "tags2", "tags3", "Destination Marketing and Tourism Development");
const education_geojsonObj = filterGeoJSONByProperty(geojsonObj, "organization_asset_type", "tags2", "tags3", "Education Resources");
const indigenous_geojsonObj = filterGeoJSONByProperty(geojsonObj, "organization_asset_type", "tags2", "tags3", "Indigenous Economic Support");
const retentionExpansion_geojsonObj = filterGeoJSONByProperty(geojsonObj, "organization_asset_type", "tags2", "tags3", "Business Retention and Expansion");
const funding_geojsonObj = filterGeoJSONByProperty(geojsonObj, "organization_asset_type", "tags2", "tags3", "Economic Development Funding Resource");
const municipalChambers_geojsonObj = filterGeoJSONByProperty(geojsonObj, "organization_asset_type", "tags2", "tags3", "Municipality and Chambers of Commerce");
const industrySpecific_geojsonObj = filterGeoJSONByProperty(geojsonObj, "organization_asset_type", "tags2", "tags3", "Industry Specific Economic Development");


/* ************map Variables************* */

/* *** VectorSources *** */
const allAssets_vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(geojsonObj)
});

const edpnMembers_vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(edpnMembers_geojsonObj)
});

const edAgencies_vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(edAgencies_geojsonObj)
});

const DMOs_vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(DMOs_geojsonObj)
});

const consultants_vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(consultants_geojsonObj)
});

const education_vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(education_geojsonObj)
});

const indigenous_vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(indigenous_geojsonObj)
});

const retentionExpansion_vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(retentionExpansion_geojsonObj)
});

const funding_vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(funding_geojsonObj)
});

const municipalChambers_vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(municipalChambers_geojsonObj)
});

const industrySpecific_vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(industrySpecific_geojsonObj)
});

const regions_vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(regions_geojson_WFS)
});


const OSMbaseMap = new TileLayer({
  title: 'Open Street Maps',
  type: 'base',
  visible: false,
  source: new OSM()
});

const BingBaseLight = new TileLayer({
  title: 'Bing Canvas Light',
  type: 'base',
  visible: true,
  source: new BingMaps({
    key: 'Aq3zTnJcwqxgsr49ctWDJQbSaZsmhkdIrDuFMBdoCXZTBE31Gl-nam7XhVkbHHy9',
    imagerySet: 'CanvasGray'
  })
});

const BingArielBase = new TileLayer({
  title: 'Bing Ariel Image',
  type: 'base',
  visible: false,
  source: new BingMaps({
    key: 'Aq3zTnJcwqxgsr49ctWDJQbSaZsmhkdIrDuFMBdoCXZTBE31Gl-nam7XhVkbHHy9',
    imagerySet: 'Aerial'
  })
});

const allAssets_Layer = new VectorLayer({
  title: 'All Assets',
  style: styleFunction,
  visible: true,
  source: allAssets_vectorSource,
});
const edpnMembers_Layer = new VectorLayer({
  title: 'EDPN Members',
  style: styleFunction_1,
  visible: false,
  source: edpnMembers_vectorSource,
});
const edAgencies_Layer = new VectorLayer({
  title: 'Economic Development Agencies',
  style: styleFunction_1,
  visible: true,
  source: edAgencies_vectorSource,
});
const DMOs_Layer = new VectorLayer({
  title: 'Destination Marketing and Tourism Development',
  style: styleFunction_1,
  visible: true,
  source: DMOs_vectorSource,
});
const consultants_Layer = new VectorLayer({
  title: 'Private Economic Development Consultant',
  style: styleFunction_1,
  visible: true,
  source: consultants_vectorSource,
});
const education_Layer = new VectorLayer({
  title: 'Education Resources',
  style: styleFunction_1,
  visible: true,
  source: education_vectorSource,
});
const indigenous_Layer = new VectorLayer({
  title: 'Indigenous Economic Support',
  style: styleFunction_1,
  visible: true,
  source: indigenous_vectorSource,
});
const retentionExpansion_Layer = new VectorLayer({
  title: 'Business Retention and Expansion',
  style: styleFunction_1,
  visible: true,
  source: retentionExpansion_vectorSource,
});
const funding_Layer = new VectorLayer({
  title: 'Economic Development Funding Resource',
  style: styleFunction_1,
  visible: true,
  source: funding_vectorSource,
});
const municipalChambers_Layer = new VectorLayer({
  title: 'Municipality and Chambers of Commerce',
  style: styleFunction_1,
  visible: true,
  source: municipalChambers_vectorSource,
});
const industrySpecific_Layer = new VectorLayer({
  title: 'Industry Specific Economic Development',
  style: styleFunction_1,
  visible: true,
  source: industrySpecific_vectorSource,
});

const regions_Layer = new VectorLayer({
  title: 'Regions',
  style: styleFunction_regions,
  opacity: 0.5,
  visible: true,
  source: regions_vectorSource,
});

const view = new View({
  center: initialView, 
  zoom: 7, 
  minZoom: 2,
  maxZoom: 20,
  crs: 'EPSG:3857',
  constrainResolution: false,
});

const baseMaps = new LayerGroup({
  title: 'Base maps',
  layers: [BingArielBase, OSMbaseMap, BingBaseLight]
})
var assetLayers = new LayerGroup({
  title: 'Economic Development Assets by Type',
  layers: [edpnMembers_Layer, DMOs_Layer, retentionExpansion_Layer, education_Layer, consultants_Layer, indigenous_Layer, funding_Layer, industrySpecific_Layer, municipalChambers_Layer, edAgencies_Layer],
  fold: 'close',
  visible: true,
})

const map = new Map({
  target: 'map',
  layers: [baseMaps, regions_Layer, /*allAssets_Layer,*/ assetLayers],
  view: view,
});

/* ******** Layer switcher functions******** */
let layer_switcher_container = document.getElementById("layerSwitcherContainer")

let layerSwitcher = new LayerSwitcher({
  reverse: true,
  startActive: layerSwitcherMediaSize(),
  collapseLabel: '',
  tipLabel: 'Layer Switcher', // Optional label for button
  groupSelectStyle: 'children', // Can be 'children' [default], 'group' or 'none'
  activationMode: 'click', // Can be 'click' [default], 'hover' or 'none'
  });

map.addControl(layerSwitcher);
let layer_switcher_button = document.querySelector('button[title="Collapse legend"]');
let layer_switcher_button_768 = document.querySelector('button[title="Layer Switcher"]');

if (layer_switcher_button) {
  layer_switcher_container.appendChild(layer_switcher_button);
} else {
  layer_switcher_container.appendChild(layer_switcher_button_768);
}
/* ******** End Layer Switcher functions ******** */

/* ************** Screen size Conditions *************** */
// Add event listener to check if screen size has changed
window.addEventListener('resize', function() {  
  if (layerSwitcherMediaSize()) {
    layerSwitcher.showPanel();
  } else {
    layerSwitcher.hidePanel();
  } 
  let viewportWidth = window.innerWidth;
  console.log(viewportWidth)
  if (viewportWidth <= 625 && viewportWidth > 480) {
    let wrapper = document.getElementById("wrapper");
    wrapper.classList.add("no-list");
  } else {
    let wrapper = document.getElementById("wrapper");
    wrapper.classList.remove("no-list");
  }
  console.log(viewportWidth)
  if (viewportWidth <= 625 && viewportWidth > 480) {
    let wrapper = document.getElementById("wrapper");
    wrapper.classList.add("no-list");
  } else {
    let wrapper = document.getElementById("wrapper");
    wrapper.classList.remove("no-list");
  }
 
  if (viewportWidth <= 480) {
    let string_value = document.getElementById("string-value");
    string_value.multiple = false;
    let legend = document.getElementById("legend-wrapper");
    legend.style.display = "none";
  } else {
    let string_value = document.getElementById("string-value");
    string_value.multiple = true;
    let legend = document.getElementById("legend-wrapper");
    legend.style.display = "block";
  }
});

function layerSwitcherMediaSize() {
  const viewportWidth = window.innerWidth;
  if (viewportWidth <= 768) {
    return false;
  }
  return true;
}


/* ***************end screen size conditions *************** */

// sync(map); need to import ol-hashed if using

/* ********************BEGIN POPUPS********************* */
var container = document.getElementById('popup'),
    content_element = document.getElementById('popup-content'),
    closer = document.getElementById('popup-closer');

closer.onclick = function() {
    overlay.setPosition(undefined);
    closer.blur();
    return false;
};
var overlay = new Overlay({
    element: container,
    autoPan: true,
    offset: [0, -10]
});

map.addOverlay(overlay);

// function to display popup content
map.on('click', function(evt){
  var feature = map.forEachFeatureAtPixel(evt.pixel,
    function(feature) {
      return feature;
    });
    if (feature) {
      var geometry = feature.getGeometry();
      var geometryType = geometry.getType();

      if (geometryType === 'Point'){
        pointPopupContent(feature);
      } 
    }
});

/* ***************BEGIN POPUP CONTENT FUNCTIONS**************** */

// Function for creating content for point feature
function pointPopupContent(feature) {
  let geometry = feature.getGeometry();
  let point_coord = geometry.getCoordinates();
  let organization_asset_type = feature.get('organization_asset_type');
  let tags2 = feature.get('tags2');
  let tags3 = feature.get('tags3');
  let website = feature.get('website');
  let webLink = '';
  let member = feature.get('edpn_member');
  let primary_contact = feature.get('primary_contact');
  let primary_asset_type = feature.get('primary_asset_type');
  let primary_title = feature.get('primary_title');
  let primary_email = feature.get('primary_email');
  let primary_emailLink = '';
  let primary_phone = feature.get('primary_phone');
  let primary_area_of_expertise = feature.get('primary_area_of_expertise');
  let secondary_contact = feature.get('secondary_contact');
  let secondary_asset_type = feature.get('secondary_asset_type');
  let secondary_title = feature.get('secondary_title');
  let secondary_email = feature.get('secondary_email');
  let secondary_emailLink = '';
  let secondary_phone = feature.get('secondary_phone');
  let secondary_area_of_expertise = feature.get('secondary_area_of_expertise');
  let region = feature.get('region');
  let community = feature.get('community');
  let notes = feature.get('notes');

  if (tags2 != 'Not Specified') {
    tags2 = `,  ${tags2}`;
  } else {
    tags2 = "";
  }
  if (tags3 != 'Not Specified') {
    tags3 = `,  ${tags3}`;
  } else {
    tags3 = "";
  }
  if (primary_email !== 'Not Specified') {
    primary_emailLink = `<a href=mailto: "${primary_email}"> ${primary_email}</a>`;
  } else {
    primary_emailLink = 'Not Specified';
  }
  if (secondary_email !== 'Not Specified') {
    secondary_emailLink = `<a href=mailto: "${secondary_email}"> ${secondary_email}</a>`;
  } else {
    secondary_emailLink = 'Not Specified';
  }
  if (website !== 'Not Specified') {
    webLink = `<a href="${website}" target="_blank">${feature.get('organization')}</a>`;
  } else {
    webLink = 'Not Specified';
  }

  let content = `
    <h2> ${feature.get('organization')} </h2> 
    <hr class=rounded >
    <h5 id=popup-organization_asset_type >ASSET TYPE - ${organization_asset_type}${tags2}${tags3}</h5>
    <h5 id=popup-website>Web: ${webLink}</h5>
    <h5 id=popup-edpn_member >EDPN Member - ${member}</h5>
    <h5 id=popup-primary_contact >Primary Contact - ${primary_contact}</h5>
    <h5 id=popup-primary_asset_type >Primary Asset Type - ${primary_asset_type}</h5>
    <h5 id=popup-primary_title >Primary Title - ${primary_title}</h5>
    <h5 id=popup-primary_email>Email: ${primary_emailLink}</h5>
    <h5 id=popup-primary_phone>Phone: ${primary_phone}</h5>
    <h5 id=popup-primary_area_of_expertise>Area of Expertise - ${primary_area_of_expertise}</h5>
    <h5 id=popup-secondary_contact >Secondary Contact - ${secondary_contact}</h5>
    <h5 id=popup-secondary_asset_type >Secondary Asset Type - ${secondary_asset_type}</h5>
    <h5 id=popup-secondary_title >Secondary Title - ${secondary_title}</h5>
    <h5 id=popup-secondary_email>Email: ${secondary_emailLink}</h5>
    <h5 id=popup-secondary_phone>Phone: ${secondary_phone}</h5>
    <h5 id=popup-secondary_area_of_expertise>Area of Expertise - ${secondary_area_of_expertise}</h5>
    <h5 id=popup-region>Region - ${region}</h5>
    <h5 id=popup-community>Community - ${community}</h5>
    <h5 id=popup-notes>Notes - ${notes}</h5>
  `;
  content_element.innerHTML = content;
  overlay.setPosition(point_coord);
}

// map.on('pointermove', function(e) {
//     if (e.dragging) return;
       
//     var pixel = map.getEventPixel(e.originalEvent);
//     var hit = map.hasFeatureAtPixel(pixel);
    
//     map.getTarget().style.cursor = hit ? 'pointer' : '';
// });
/* ***************END POPUP CONTENT FUNCTIONS**************** */
/* ********************END POPUPS********************* */

/* ***************BEGIN STYLE FUNCTIONS**************** */

function styleFunction () {
  const markerSource = markerURL; 

  var markerStyle = new Style({
    image: markerSource ? new Icon({
      src: markerSource,
      scale: 0.5,
      anchor : [0.5, 1],  
    }) : undefined
  });
  return [markerStyle];
};

function styleFunction_regions (feature) {
  let regionColors = [
    [139, 207, 36],
    [73, 154, 211],
    [134, 203, 49],
    [78, 158, 198],
    [129, 199, 62],
    [83, 162, 185],
    [124, 195, 75],
    [88, 166, 172],
    [119, 191, 88],
    [92, 170, 159],
    [114, 187, 101],
    [97, 174, 146],
    [109, 183, 114],
    [102, 178, 133],
    [105, 180, 127],
    [107, 181, 120],
    [100, 176, 140],
    [112, 185, 107],
    [95, 172, 153],
    [117, 189, 94],
    [90, 168, 166],
    [122, 193, 81],
    [85, 164, 179],
    [126, 197, 68],
    [80, 160, 192],
    [131, 201, 55],
    [75, 156, 205],
    [136, 205, 42],
    [71, 153, 218]
 ];
  
  let fid = feature.get('fid');
  let colorIndex = fid % regionColors.length;
  let assignedColor = regionColors[colorIndex];
  let regionStyle = new Style({
    stroke: new Stroke({
      color: 'black',
      width: 1
    }),
    fill: new Fill({
      color: assignedColor
    })
  });
  return [regionStyle];
}

/** functions to style asset layers* */
const markerSource_1 = edAgencies_markerURL; 
const markerSource_2 = consultants_markerURL; 
const markerSource_3 = DMOs_markerURL; 
const markerSource_4 = education_markerURL; 
const markerSource_5 = indigenous_markerURL; 
const markerSource_6 = retentionExpansion_markerURL; 
const markerSource_7 = funding_markerURL; 
const markerSource_8 = municipalChambers_markerURL; 
const markerSource_9 = industrySpecific_markerURL; 
const markerSource_10 = edAgencies_member_markerURL; 
const markerSource_11 = consultants_member_markerURL; 
const markerSource_12 = DMOs_member_markerURL; 
const markerSource_13 = education_member_markerURL; 
const markerSource_14 = indigenous_member_markerURL; 
const markerSource_15 = retentionExpansion_member_markerURL; 
const markerSource_16 = funding_member_markerURL; 
const markerSource_17 = municipalChambers_member_markerURL; 
const markerSource_18 = industrySpecific_member_markerURL; 


const scale = 0.6;
const anchor = [0.5, 1];

const createStyle = (markerSource) => {
  return new Style({
    image: markerSource ? new Icon({
      src: markerSource,
      scale,
      anchor
    }) : undefined
  });
}

const organizationAssetTypeMarkerMap = {
  'Industry Specific Economic Development': markerSource_9,
  'Municipality and Chambers of Commerce': markerSource_8,
  'Economic Development Funding Resource': markerSource_7,
  'Business Retention and Expansion': markerSource_6,
  'Indigenous Economic Support': markerSource_5,
  'Destination Marketing and Tourism Development': markerSource_3,
  'Education Resources': markerSource_4,
  'Private Economic Development Consultant': markerSource_2,
  'Economic Development Agencies': markerSource_1,
};

const edpnMemberMarkerMap = {
  'Industry Specific Economic Development': markerSource_18,
  'Municipality and Chambers of Commerce': markerSource_17,
  'Economic Development Funding Resource': markerSource_16,
  'Business Retention and Expansion': markerSource_15,
  'Indigenous Economic Support': markerSource_14,
  'Destination Marketing and Tourism Development': markerSource_12,
  'Education Resources': markerSource_13,
  'Private Economic Development Consultant': markerSource_11,
  'Economic Development Agencies': markerSource_10,
};

function getMarkerUrl(feature) {
  const assetType = feature.get('organization_asset_type');
  const isEdpnMember = feature.get('edpn_member') === 'yes';
  const baseMarkerUrl = organizationAssetTypeMarkerMap[assetType];
  const memberMarkerUrl = isEdpnMember ? edpnMemberMarkerMap[assetType] : null;

  return memberMarkerUrl || baseMarkerUrl;
}

function styleFunction_1(feature) {
  const markerUrl = getMarkerUrl(feature);
  return createStyle(markerUrl);
}

// function styleFunction_1(feature) {
//   let baseStyle;

//   switch (feature.get('organization_asset_type')) {
//     case 'Industry Specific Economic Development':
//       baseStyle = createStyle(markerSource_9);
//       break;
//     case 'Municipality and Chambers of Commerce':
//       baseStyle = createStyle(markerSource_8);
//       break;
//     case 'Economic Development Funding Resource':
//       baseStyle = createStyle(markerSource_7);
//       break;
//     case 'Business Retention and Expansion':
//       baseStyle = createStyle(markerSource_6);
//       break;
//     case 'Indigenous Economic Support':
//       baseStyle = createStyle(markerSource_5);
//       break;
//     case 'Destination Marketing and Tourism Development':
//       baseStyle = createStyle(markerSource_3);
//       break;
//     case 'Education Resources':
//       baseStyle = createStyle(markerSource_4);
//       break;
//     case 'Private Economic Development Consultant':
//       baseStyle = createStyle(markerSource_2);
//       break;
//     case 'Economic Development Agencies':
//       baseStyle = createStyle(markerSource_1);
//       break;
//     default:
//       baseStyle = new Style({});
//   }

//   // Check if the feature is an EDPN Member and 'yes', then apply both styles
//   console.log(feature.get('edpn_member')); // Add this line to debug
//   if (feature.get('edpn_member') === 'yes') {
//     const edpnStyle = createStyle(markerSource_10);
//     return [edpnStyle, baseStyle]; // Return an array of styles
//   } else {
//     return baseStyle; // Return the base style only
//   }
// }

// function styleFunction_1 (feature) {
//   switch (feature.get('organization_asset_type')) {
//     case 'Industry Specific Economic Development':
//       return createStyle(markerSource_9);
//     case 'Municipality and Chambers of Commerce':
//       return createStyle(markerSource_8);
//     case 'Economic Development Funding Resource':
//       return createStyle(markerSource_7);
//     case 'Business Retention and Expansion':
//       return createStyle(markerSource_6);
//     case 'Indigenous Economic Support':
//       return createStyle(markerSource_5);
//     case 'Destination Marketing and Tourism Development':
//       return createStyle(markerSource_3);
//     case 'Education Resources':
//       return createStyle(markerSource_4);
//     case 'Private Economic Development Consultant':
//       return createStyle(markerSource_2);
//     case 'Economic Development Agencies':
//       return createStyle(markerSource_1);
//     default:
//       return new Style({});
//   }
// }
// function styleFunction_edAgencies () {
//   return createStyle(markerSource_1);
// }
// function styleFunction_consultants () {
//   return createStyle(markerSource_2);
// }
// function styleFunction_DMOs () {
//   return createStyle(markerSource_3);
// }
// function styleFunction_education () {
//   return createStyle(markerSource_4);
// }
// function styleFunction_indigenous () {
//   return createStyle(markerSource_5);
// }
// function styleFunction_retention () {
//   return createStyle(markerSource_6);
// }
// function styleFunction_funding () {
//   return createStyle(markerSource_7);
// }
// function styleFunction_municipal () {
//   return createStyle(markerSource_8);
// }
// function styleFunction_industry () {
//   return createStyle(markerSource_9);
// }
// function styleFunction_edpnMembers () {
//   return createStyle(markerSource_10);
// }

// function styleFunction_edpnMembers (feature) {
//   if (feature.get('edpn_member') === 'yes') {
//     return createStyle(markerSource_10);
//   }
// }

/**end of asset layer functions */

function styleSelect () {  
  const stroke_blu = new Stroke({
    color: 'blue',
    width: 2,
  });
  let style = new Style({
    stroke: stroke_blu,
  });
  return [style];
  };
/* ***************END STYLE FUNCTIONS**************** */

/* ****DOM element variables**** */
let groups = document.getElementById("groups");
let List = document.getElementById('list');
let FeatureList = document.getElementById("feature-list");
let list_toggle_img = document.querySelector('#list-toggle > img');
let featureCloser = document.getElementById('feature-closer');
let wrapper = document.getElementById('wrapper');
let current_view_values = []; // holding variable for current mapview
let tableid = ""; //holding variable for clicked business

/* ****event listeners**** */
featureCloser.addEventListener('click', showList);
list_toggle_img.addEventListener('click', hideList);

// create elements for business list and set attributes
active_regions.forEach((group)=>{
  let li = document.createElement("li");
  let ul = document.createElement("ul");
  let a = document.createElement("a");
  let img = document.createElement("img");
  let span = document.createElement("span");
  let node = document.createTextNode(group.region);
  li.classList.add("regions");
  li.setAttribute("id", "li_" + group.region);
  a.setAttribute("id", "a_" + group.region);
  a.setAttribute("href", "#");
  img.setAttribute("src", "https://trugis.ca/wp-content/uploads/2023/02/opened_blu.png");
  img.setAttribute("alt", "");
  img.setAttribute("id", group.region);
  img.setAttribute("border", "0");
  ul.setAttribute("id", "ul_" + group.region);
  span.setAttribute("id", group.id);
  a.appendChild(img);
  a.appendChild(span);
  a.appendChild(span);
  span.appendChild(node);
  li.appendChild(a);
  groups.appendChild(li);
  li.appendChild(ul);
  //sub-lists
  jsonObj.forEach((item)=>{
    let organization = item.organization;

    if (group.region === item.region){
    let li = document.createElement('li');
    let a = document.createElement('a');
    a.setAttribute('id', item.id);
    a.setAttribute('href', '#');
    a.classList.add('business');
    a.classList.add('nofocus');
    //a.innerText = formattedName;
    a.innerText = organization;
    ul.appendChild(li);
    li.appendChild(a);
    }
  });
});

// filter business attributes for feature panel
const filter_businessATT_jsonObj = [];
jsonObj.forEach((item)=>{
  const selectedFields = ['organization', 'organization_asset_type', 'edpn_member', 'address_1', 'website', 'primary_contact', 'primary_asset_type','primary_title', 'primary_email', 'primary_area_of_expertise', 'secondary_contact', 'secondary_asset_type','secondary_title', 'secondary_email', 'secondary_area_of_expertise', 'region', 'community', 'tags2', 'tags3', 'notes']
  const arr = Object.keys(item)
  .filter((key) => selectedFields.includes(key))
  .reduce((obj, key) => {
    obj[key] = item[key];
    return obj;
  }, {});
  filter_businessATT_jsonObj.push(arr);
});
//console.log(filter_businessATT_jsonObj); 

/* ****function to create and populate business feature**** */
for (let i =0; i < filter_businessATT_jsonObj.length; i++) {  
  // replace character delimiters
  let organization_asset_type = (filter_businessATT_jsonObj[i].organization_asset_type) ? filter_businessATT_jsonObj[i].organization_asset_type : 'No Asset Category';
  let tags2 = (filter_businessATT_jsonObj[i].tags2) ? filter_businessATT_jsonObj[i].tags2 : 'No Asset Type';
  let tags3 = (filter_businessATT_jsonObj[i].tags3) ? filter_businessATT_jsonObj[i].tags3 : 'No Asset Type';
  // if (organization_asset_type) {
  organization_asset_type = organization_asset_type.split("|");
  tags2 = tags2.split("|");
  tags3 = tags3.split("|");
  // } else {
  //   organization_asset_type = 'noTag';
  // }
  // console.log(organization_asset_type)
  let attributes = document.getElementById('attributes');
  let table = document.createElement("table");
  table.id = 'table-' + [i]; 
  table.classList.add('hidden');
  attributes.appendChild(table);
  
  for(let attribute in filter_businessATT_jsonObj[i]) {  
    let tr = document.createElement("tr"); 
    let th = document.createElement("th");
    let td = document.createElement("td");
    let a = document.createElement("a");
   
    if (attribute != 'organization' && attribute != 'tags2' && attribute != 'tags3') {
      // Replace underscores with spaces and remove "primary" or "secondary" if they are at the beginning and remove the "1" from the tail of address
      const formattedAttribute = attribute.replace(/^primary|^secondary/, '').replace(/_/g, " ").replace(/1$/, '');

      th.id = attribute;
      th.innerText = formattedAttribute;
      td.id = attribute + '-value';
      
      if (th.id === 'organization') {
        td.innerText = filter_businessATT_jsonObj[i].organization;
      } else if (th.id === 'address_1') {
          td.innerText = filter_businessATT_jsonObj[i].address_1;
      } else if (th.id === 'edpn_member') {
          td.innerText = filter_businessATT_jsonObj[i].edpn_member; 
      } else if (th.id === 'primary_contact') {
          td.innerText = filter_businessATT_jsonObj[i].primary_contact;
      } else if (th.id === 'primary_title') {
          td.innerText = filter_businessATT_jsonObj[i].primary_title;
      } else if (th.id === 'primary_phone') {
          td.innerText = filter_businessATT_jsonObj[i].primary_phone;
      } else if (th.id === 'primary_email') {
        if (filter_businessATT_jsonObj[i].primary_email !== "Not Specified") {
          let a = document.createElement("a");
          a.id = attribute + '-innerValue';
          a.setAttribute("href", "mailto:" + filter_businessATT_jsonObj[i].primary_email);
          a.innerText = filter_businessATT_jsonObj[i].primary_email;
          td.appendChild(a);
        } else {
          td.innerText = "Not Specified";
        }
      } else if (th.id === 'primary_area_of_expertise') {
        td.innerText = filter_businessATT_jsonObj[i].primary_area_of_expertise;
      } else if (th.id === 'secondary_contact') {
          td.innerText = filter_businessATT_jsonObj[i].secondary_contact;
      } else if (th.id === 'secondary_title') {
          td.innerText = filter_businessATT_jsonObj[i].secondary_title;
      } else if (th.id === 'secondary_phone') {
          td.innerText = filter_businessATT_jsonObj[i].secondary_phone;
      } else if (th.id === 'secondary_email') {
        if (filter_businessATT_jsonObj[i].secondary_email !== "Not Specified") {
          let a = document.createElement("a");
          a.id = attribute + '-innerValue';
          a.setAttribute("href", "mailto:" + filter_businessATT_jsonObj[i].secondary_email);
          a.innerText = filter_businessATT_jsonObj[i].secondary_email;
          td.appendChild(a);
        } else {
          td.innerText = "Not Specified";
        }
      } else if (th.id === 'secondary_area_of_expertise') {
        td.innerText = filter_businessATT_jsonObj[i].secondary_area_of_expertise;
      }  else if (th.id === 'website') {
        if (filter_businessATT_jsonObj[i].website !== "Not Specified") {
          let a = document.createElement("a");
          a.id = attribute + '-innerValue';
          a.setAttribute("href", filter_businessATT_jsonObj[i].website);
          a.setAttribute("target", "_blank");
          a.innerText = filter_businessATT_jsonObj[i].organization;
          td.appendChild(a);
        } else {
          td.innerText = "Not Specified";
        }
      } else if (th.id === 'region') {
        td.innerText = filter_businessATT_jsonObj[i].region;
      } else if (th.id === 'community') {
        td.innerText = filter_businessATT_jsonObj[i].community;
      } else if (th.id === 'notes' && filter_businessATT_jsonObj[i].notes !== "Not Specified") {
        td.innerText = filter_businessATT_jsonObj[i].notes;
      };
      // create buttons from organization_asset_type
      if (th.id == 'organization_asset_type') {
        organization_asset_type.forEach(function (tag, index) {
          let tagButton = document.createElement('button');
          tagButton.id = `tag_${tag}_${index}`; 
          tagButton.className = "tags";
          tagButton.innerHTML = tag;
          tagButton.value = tag;
          td.appendChild(tagButton);
        });
      };
      if (th.id == 'organization_asset_type' && tags2 != "Not Specified") {
        tags2.forEach(function (tag, index) {
          let tagButton = document.createElement('button');
          tagButton.id = `tag_${tag}_${index}`; 
          tagButton.className = "tags";
          tagButton.innerHTML = tag;
          tagButton.value = tag;
          td.appendChild(tagButton);  
         });
       };
      if (th.id == 'organization_asset_type' && tags3 != "Not Specified") {
        tags3.forEach(function (tag, index) {
          let tagButton = document.createElement('button');
          tagButton.id = `tag_${tag}_${index}`; 
          tagButton.className = "tags";
          tagButton.innerHTML = tag;
          tagButton.value = tag;
          td.appendChild(tagButton);  
         });
       };
     };
    tr.appendChild(th);
    tr.appendChild(td);
    table.appendChild(tr); 
  };
};

/* ******************BEGIN FILTER FUNCTIONS***************** */
// instantiate variables to hold filtered layers
let geojsonObj_filtered;
let vectorSource_filtered;
let Layer_filtered;

// Get the property select and value select elements
var propertySelect = document.getElementById("property1");
var filter_submit_btn = document.getElementById("filter-submit-btn");
var string_label = document.getElementById("string-label");
var string_value = document.getElementById("string-value");
var range_input = document.getElementById("range-input");
var low_number_value = document.getElementById("low-number-value");
var high_number_value = document.getElementById("high-number-value");
var rangeValues = {low: '', high: ''};
var rangeArray;
var string_values_array;

geojsonObj.features.forEach(feature => {
  if (feature.properties.hasOwnProperty('organization_asset_type') && feature.properties.hasOwnProperty('tags2') && feature.properties.hasOwnProperty('tags3')) {
    if (feature.properties.tags2 !== "Not Specified" || feature.properties.tags3 !== "Not Specified") {
      feature.properties.combined_tags = feature.properties.organization_asset_type + ', ' + feature.properties.tags2 + ', ' + feature.properties.tags3;
    } else {
      feature.properties.organization_asset_type = feature.properties.organization_asset_type;
    }
  } else if (feature.properties.hasOwnProperty('organization_asset_type')) {
    feature.properties.organization_asset_type = feature.properties.organization_asset_type;
  } else if (feature.properties.hasOwnProperty('tags2') && feature.properties.tags2 !== "Not Specified") {
    feature.properties.combined_tags = feature.properties.tags2;
  } else if (feature.properties.hasOwnProperty('tags3') && feature.properties.tags3 !== "Not Specified") {
    feature.properties.combined_tags = feature.properties.tags3;
  }
});
console.log('geojsonObj 1247:', geojsonObj)

// Extract unique properties from GeoJSON object
let properties = new Set();
geojsonObj.features.forEach(feature => {
  const filterArray = ['organization', 'primary_title', 'region', 'community', 'primary_area_of_expertise', 'organization_asset_type', 'secondary_title', 'secondary_area_of_expertise'];

  Object.keys(feature.properties).forEach(property => {
    if(filterArray.includes(property)) {
      properties.add(property);
    } 
  });
});

// Add options to the property select
properties.forEach(property => {
  var option = document.createElement("option");
  option.value = property;
  option.innerHTML = property;
  propertySelect.appendChild(option);
});

// Add event listener to the property select to update the value select
propertySelect.addEventListener("change", function() {
  // Get the selected property
  var selectedProperty = propertySelect.value;
  // Clear the user input values 
  string_value.innerHTML = "";
  low_number_value.innerHTML = "";
  high_number_value.innerHTML = "";

  // Extract unique values for selected property from GeoJSON object
  let values = new Set();
  geojsonObj.features.forEach(feature => {
    if(feature.properties[selectedProperty]) 
      values.add(feature.properties[selectedProperty]);
  });
  // sort values ascending
  let valuesArray = Array.from(values);
  valuesArray.sort();
  values = new Set(valuesArray);

  // Add options to the value select
  values.forEach(value => {
    if (typeof value === 'string') {
      if (range_input.style.display === "block") {
        range_input.style.display = "none"
      }
      var option = document.createElement("option");
      option.value = value;
      option.innerHTML = value;
      string_label.style.display = "block"
      string_value.style.display = "block"
      string_value.appendChild(option);
    } else if (typeof value === 'number') {
        if (string_label.style.display === "block") {
          string_label.style.display = "none"
          string_value.style.display = "none"
        }
      rangeArray = values;
      range_input.style.display = "block"
      // sort array to extract low and high value
      let sortedNumbers = [...values].slice().sort((a, b) => a - b);
      // round decimal values to 4 places
      let roundedNumbers = sortedNumbers.map(number => Math.round(number * 10000) / 10000);
      //calculate decimal length to be used to change input increment
      let decimalLength;
      if(Number.isInteger(roundedNumbers[0])) {
        decimalLength = 0;
      } else {  
        decimalLength = (roundedNumbers[0]).toString().split(".")[1].length;
      } 
      // convert decimal length to increment value
      let placeholder = 1 
      let incrementor = placeholder / Math.pow(10, decimalLength)
      //set increment value for input arrows
      low_number_value.step = incrementor;
      high_number_value.step = incrementor;
      //set low and high values of array
      low_number_value.value = roundedNumbers[0];
      high_number_value.value = roundedNumbers[sortedNumbers.length - 1];
      rangeValues = {low: roundedNumbers[0], high: roundedNumbers[sortedNumbers.length - 1]}
      //event listeners for retrieving low and high values
      low_number_value.addEventListener("change", function() {
        rangeValues.low = low_number_value.valueAsNumber;
      });
      high_number_value.addEventListener("change", function() {
        rangeValues.high = high_number_value.valueAsNumber;
      }); 
    }
  });
});

// Add event listener to the filter submit button and adds filtered layer to map
filter_submit_btn.addEventListener("click", function() {
  overlay.setPosition(undefined);
  current_view_values = {
    currentCenter: active_regions[0].coords,
    currentZoom: 11
  }
  //console.log(current_view_values)
 
  geojsonObj_filtered = submitFilter(propertySelect.value, string_value.selectedOptions);
  
  let filter_title;
  if (typeof string_values_array[0] === 'string') {
    filter_title = `Filtered ${propertySelect.value}<br> = ${string_values_array}`
  } else {
    filter_title = `Filtered ${propertySelect.value}<br> >= ${rangeValues.low} <= ${rangeValues.high}`
  }
  
  createFilteredSource(geojsonObj_filtered)
  createFilteredLayer(vectorSource_filtered, filter_title)
  let extent = vectorSource_filtered.getExtent()
  //console.log(extent)
  
  addFilterLayer (Layer_filtered, map, extent)  
  FilterCloser (Layer_filtered, map)
});

// Filter GeoJSON object based on user input values and value type and returns new filtered feature collection
function submitFilter(property, values) {
    string_values_array = Array.from(values).map(option => option.value);
    var filteredFeatures;
    console.log(string_values_array)
    
    if (property === 'organization_asset_type') {
      filteredFeatures = geojsonObj.features.filter(function(feature) {
        return string_values_array.some(function(value) {
          return feature.properties['organization_asset_type'].includes(value) || (feature.properties['tags2'] && feature.properties['tags2'].includes(value)) || (feature.properties['tags3'] && feature.properties['tags3'].includes(value))
        });
      });
    } else if (typeof string_values_array[0] === 'string') {
      filteredFeatures = geojsonObj.features.filter(function(feature) {
        return string_values_array.includes(feature.properties[property]);
      });
    } else {
      var filterValues = Array.from(rangeArray).filter(val => val >= rangeValues.low && val <= rangeValues.high);
      filteredFeatures = geojsonObj.features.filter(function(feature) {
        return filterValues.includes(feature.properties[property]);
      });
    }
    //console.log(filteredFeatures)
    return {
      "type": "FeatureCollection",
      "features": filteredFeatures,
      "crs": {
        "type": "name",
        "properties": { "name": "urn:ogc:def:crs:EPSG::3857" }
      }  
    }  
};
// /* ********** END FILTER FUNCTIONS ********** */


// Creates a new vector source from the filtered GeoJSON object
function FilterCloser (filteredLayer, layerGroup1) {
  var filter_clear_btn = document.getElementById('filter-clear-btn');
  filter_clear_btn.addEventListener("click", function() {
    propertySelect.value = "";
    string_value.value = "";
    string_label.style.display = "none"
    string_value.style.display = "none"
    range_input.style.display = "none"
    removeFilterLayer(filteredLayer, layerGroup1)
  });
}

function addFilterLayer (filteredLayer, layerGroup, extent) {
  //console.log(layerGroup)
  retentionExpansion_Layer.setVisible(false)
  indigenous_Layer.setVisible(false)
  education_Layer.setVisible(false)
  DMOs_Layer.setVisible(false)
  consultants_Layer.setVisible(false)
  edAgencies_Layer.setVisible(false)
  funding_Layer.setVisible(false)
  municipalChambers_Layer.setVisible(false)
  industrySpecific_Layer.setVisible(false)
  layerGroup.getLayers().push(filteredLayer)
  layerSwitcher.renderPanel()
  let mapSize = map.getSize()
  view.fit(extent, {
    size: mapSize, 
    padding: [50, 50, 50, 50],
    duration: 3000,
  });
}
function removeFilterLayer(filteredLayer, layerGroup1) {
  //console.log(filteredLayer)
  layerGroup1.getLayers().remove(filteredLayer)
  retentionExpansion_Layer.setVisible(true)
  indigenous_Layer.setVisible(true)
  education_Layer.setVisible(true)
  DMOs_Layer.setVisible(true)
  consultants_Layer.setVisible(true)
  edAgencies_Layer.setVisible(true)
  funding_Layer.setVisible(true)
  municipalChambers_Layer.setVisible(true)
  industrySpecific_Layer.setVisible(true)
  layerSwitcher.renderPanel()
  view.animate({
    center: initialView, 
    zoom: 6.5,
    duration: 1000,
  });
}
function createFilteredLayer(source, title) {
  Layer_filtered = new VectorLayer({
    title: title,
    style: styleFunction_1, 
    visible: true,
    source: source,
  });
  return Layer_filtered;
}
function createFilteredSource(filteredSource) {
  vectorSource_filtered = new VectorSource({
    features: new GeoJSON().readFeatures(filteredSource)
  });
  return vectorSource_filtered;
}

// function to open filter pane
let filter_pane = document.getElementById("filter-pane");
let filter_button = document.getElementById("filter-button");
let filter_closer = document.getElementById('filter-closer');

filter_button.addEventListener('click', openFilterPane);
filter_closer.addEventListener('click', closeFilterPane);

function openFilterPane() {  
  FeatureList.style.display = "none";
  List.style.display = "none";
  filter_pane.style.display = "block"; 
  let viewportWidth = window.innerWidth;
  let string_value = document.getElementById("string-value");  
  if (viewportWidth <= 480) {
      string_value.multiple = false;
    } else {
      string_value.multiple = true;
    }
  };

// function to close filter pane
function closeFilterPane() {
  List.style.display = "block"; 
  filter_pane.style.display = "none";
}
/* ********** END FILTER FUNCTIONS ********** */

/* *** begin business list functions *** */
/* dynamically created DOM element selections */
const groupItems = document.querySelector('#groups');
const regionItems = document.querySelectorAll('.regions > a > span');
const listItems = document.querySelectorAll('.business');

// toggles regional business list open/closed
toggle_businessList();
function toggle_businessList() {
  groupItems.addEventListener('click', (e) => 
  {
    // console.log(e.target.id);
    let ul = "ul_" + e.target.id;
    let img = e.target.id;
    // console.log(ul);
    // console.log(img);
    let ulElement = document.getElementById(ul);
    // console.log(ulElement);
    let imgElement = document.getElementById(img);
    // console.log(imgElement)
    if (ulElement){
      if (ulElement.className == 'closed'){
        ulElement.className = "open";
        imgElement.src = 'https://trugis.ca/wp-content/uploads/2023/02/opened_blu.png'; 
        }else{
        ulElement.className = "closed";
        imgElement.src = 'https://trugis.ca/wp-content/uploads/2023/02/closed_blu.png'; 
      }
    }
  });
};

// opens business attribute pane and zooms to business
listClick();
function listClick() {
  
  // click event listener for groups headings/regions
  regionItems.forEach(item => {
    item.addEventListener('click', regionCLick); 
  });

  // click event listener for businesses
  listItems.forEach(item => {
    item.addEventListener('click', (event) => {
      //clear popups
      overlay.setPosition(undefined);
      // Retrieve id from clicked element
      let eleId = event.target.id;
      // retrieve longitude from json using ID
      let coordX = jsonObj[eleId].longitude;
      // retrieve latitude from json using ID
      let coordY = jsonObj[eleId].latitude;
      // convert coords from lat-long to UTM
      let coords = fromLonLat([coordX, coordY]);
      let tableID = `table-${eleId}`;
      let table = document.getElementById(tableID);
      tableid = tableID;
      // If element has id, build company info pane based on id 
      if (eleId !== '') {
        // get current view value 
        current_view_values = getValues();
        // hide list, show business info pane
        List.style.display = "none";
        FeatureList.style.display = "block";
        table.classList.remove('hidden');
        // populate business info fields
        business.innerText = jsonObj[eleId].organization;
        companyIcon.src = jsonObj[eleId].logo;
        view.animate({
          center: coords,
          zoom: 17,
          duration:2000,
          constrainResolution:true
        });
      }
      // if element has no id
      else { 
          console.log("An element without an id was clicked.");
      }
    });  
  });
};

// slides left panel closed or toggles panel open
function hideList() {
    toggleImage(list_toggle_img, panel_close, panel_open);
    // Update container size, timeout must match or exceed css transition for list pane closing
    setTimeout(function () {
      map.updateSize();
    }, 500)
    wrapper.classList.toggle('no-list');
};

// hides feature attribute list and shows main business list and zooms back out to previous position
function showList() {
  let search_input = document.getElementById('search-input');
  search_input.value = '';
  FeatureList.style.display = "none";
  List.style.display = "flex"; 
  let table = document.getElementById(tableid);
  //console.log(table);
  table.classList.add('hidden');
  view.animate({
    center: current_view_values.currentCenter, 
    zoom: current_view_values.currentZoom,
    duration: 1000,
  });
};

// function to zoom in to region from clicking regional heading
function regionCLick() {
  // Retrieve id from clicked element
  let eleId = this.id;
  // console.log(eleId);
  
  // If element has id
  if (eleId !== '') {
    view.animate({
      center: regions[eleId].coords,
      zoom: regions[eleId].zoom,
      duration: 3000,
      constrainResolution: true
    });
  }
  // If element has no id
  else { 
      console.log("An element without an id was hovered.");
  }
};
/* *** end business list functions *** */

// function to toggle image
function toggleImage(element, img_1, img_2) {
  if (wrapper.classList.contains('no-list')) {
    element.src = img_1;
  } else {
    element.src = img_2;
  }
}

// function to get current mapview values
function getValues() {
  let currentCenter = view.getCenter();
  //console.log(currentCenter);
  let currentZoom = view.getZoom();
  //console.log(currentZoom);
  let viewValues = {
    currentCenter: currentCenter,
    currentZoom: currentZoom
  }
  // console.log(viewValues);
  return viewValues;
};

/* *** begin splash-screen button and pane *** */
const splash_screen = document.getElementById("splash-screen");
const splash_button = document.getElementById("splash-button");
const splash_closer = document.getElementById("splash-closer");

splash_button.addEventListener("click", function() {  
  if (splash_screen.style.display === "block") {
    splash_screen.style.display = "none";
  } else {
    splash_screen.style.display = "block";
  }
});
splash_closer.addEventListener("click", function() {  
  if (splash_screen.style.display === "none") {
    splash_screen.style.display = "block";
  } else {
    splash_screen.style.display = "none";
  }
});
/* *** end splash-screen button and pane *** */

/* *** Begin legend button and pane *** */
const legend_wrapper = document.getElementById("legend-wrapper");
const legend_button = document.getElementById("legend-button");
const legend_closer = document.getElementById("legend-closer");

legend_button.addEventListener("click", function() {  
  if (legend_wrapper.style.display === "none") {
    legend_wrapper.style.display = "block";
  } else {
    legend_wrapper.style.display = "none";
  }
});
legend_closer.addEventListener("click", function() {  
    legend_wrapper.style.display = "none";
});

function legend() {
  let src = document.getElementById("legend");
  let legend = "https://trugis.ca/wp-content/uploads/2024/03/Legend_3.png"
  let img = new Image();
  img.src = legend;
  src.appendChild(img);
}
legend();
/* *** End Legend button and pane *** */

/* *** Begin fuzzy search functions *** */
const fuzzySearchBrowsersList = fuzzySearch(geoJSONPointArr, [
  {
    name: 'properties.organization',
    weight: 1  
  }, 
  {
    name: 'properties.organization_asset_type',
    weight: 0.8
  }, 
  {
    name: 'properties.tags2',
    weight: 0.8
  }, 
  {
    name: 'properties.tags3',
    weight: 0.8
  }, 
  {
    name: 'properties.primary_contact',
    weight: 0.7
  }, 
  {
    name: 'properties.secondary_contact',
    weight: 0.5
  }, 
  {
    name:'properties.community',
    weight: 0.6
  }, 
  {
    name: 'properties.region',
    weight: 0.5
  },
  {
    name: 'properties.primary_area_of_expertise',
    weight: 0.4
  },
  {
    name: 'properties.secondary_area_of_expertise',
    weight: 0.2
  },
  {
    name: 'properties.primary_title',
    weight: 0.3
  },
  {
    name: 'properties.secondary_title',
    weight: 0.1
  },
]);
  
/** @type {HTMLInputElement} */
const browserInputElement = document.getElementById(BROWSER_INPUT_ELEMENT_ID);

// Filter the browsers list when the browser input changes
browserInputElement.addEventListener('input', () => {
  const searchKeyword = browserInputElement.value;
  const filteredList = fuzzySearchBrowsersList(searchKeyword);
  const cleanFilteredList = filteredList.slice(0, BROWSER_SUGGESTIONS_MAX_SIZE).map(el => el.item.properties.organization);
  renderInputSuggestions(browserInputElement, cleanFilteredList);
});

/**
 * Renders a dropdown list of suggestions for an input element.
 *
 * @param {HTMLInputElement} inputEl
 * @param {Array<string>} suggestions
 * @returns {void}
 */
const renderInputSuggestions = (inputEl, suggestions) => {
  /**
   * <app-dropdown
   *   [id]="BROWSER_SUGGESTIONS_ELEMENT_ID"
   *   [options]="suggestions"
   *   [connectedTo]="inputEl">
   */

  /** @type {AppDropdownElement} */
  const existingEl = document.getElementById(BROWSER_SUGGESTIONS_ELEMENT_ID);
  if (existingEl) {
    existingEl.options = suggestions;
    existingEl.connectedTo = inputEl;
    return;
  }

  /** @type {AppDropdownElement} */
  const createdEl = document.createElement('app-dropdown');
  createdEl.id = BROWSER_SUGGESTIONS_ELEMENT_ID;
  createdEl.options = suggestions;
  createdEl.connectedTo = inputEl;

  // On click, set the input value to the suggestion
  createdEl.addEventListener('option-select', () => {
    console.log('option-select', createdEl.selected);
    inputEl.value = createdEl.selected;
    createdEl.remove();
    const result = geojsonObj.features.find(function(feature) {
      return feature.properties.organization === createdEl.selected;
    });
    const coords = result ? result.geometry.coordinates : undefined;
    // get current view value 
    current_view_values = getValues();
    // get current table id
    let tableID = `table-${result.properties.id}`;
    let table = document.getElementById(tableID);
    tableid = tableID;
   
    // hide list, show business info pane
    List.style.display = "none";
    FeatureList.style.display = "block";

    // Loop through all tables to check for hidden class
    const allTables = document.querySelectorAll('#attributes table');
    allTables.forEach(table => {
      if (!table.classList.contains('hidden')) {
        table.classList.add('hidden');
      }
    });

    table.classList.remove('hidden');
    // populate business info fields
    business.innerText = result.properties.organization;
    companyIcon.src = result.properties.logo;
    view.animate({
            center: coords,
            zoom: 17,
            duration:2000,
            constrainResolution:true
          });
    console.log('coords', coords);
  });
  document.documentElement.appendChild(createdEl);
};
/* *** End fuzzy search functions *** */





